import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";

const DownloadReportPage = () => {

    return (
        <div>
            Download Report Page
        </div>
    )
}

export default DownloadReportPage;